<template>
<!--  <div class="nav-container">-->
    <div class="nav">
      <div id="navTab">
        <a href="/" class="logo">
          <!-- <img src="@/assets/logo/pubrio-square with radius.png" /> -->
          <img src="@/assets/logo/pubrio-logo.png" />
        </a>

        <top-nav style="flex:10;"/>
      </div>

      <div id="smallNav"  >
        <el-button @click="drawer = true">
          <svg-icon icon-class="list"/></el-button>

        <div class="active-catalog">
          {{catalog}}
        </div>
      </div>

      <el-drawer
          :append-to-body="true"
          :visible.sync="drawer"
          direction="ltr">
        <span slot="title">
          <a href="/" class="logo-1">
          <img src="@/assets/logo/pubrio-logo.png" />
        </a>
        </span>
        <ul class="list-menu" >
          <li @click="closeDrawer" v-for="(item,index) in listMenu" :key="index">
            <router-link :to="{name:item.name}" v-if="item.name !== 'company-detail'" >{{item.meta.title}}</router-link>
          </li>
        </ul>
      </el-drawer>

      <div class="blank"></div>
      <top-right style="flex:3;"/>
    </div>



</template>

<script>
import topNav from "@/components/topNav";
import topRight from "@/components/top-right";
export default {
  name: "navBar",
  components:{
    topNav,
    topRight,
  },
  data(){
    return{
      drawer: false,
      screenWidth: null,  //屏幕宽度
    }
  },
  computed:{
    catalog(){
      return this.$route.meta.title !== 'companyDetail' ? this.$route.meta.title : 'Company'
    },
    listMenu(){
      return this.$router.options.routes[3].children
    }
  },
  watch: {
    screenWidth: {
      handler: function (val) {
        if(val>767){
          this.drawer = false
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      this.screenWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;
    };
  },

  methods:{
    closeDrawer(){
      this.drawer = false
    }
  }

}

</script>

<style scoped lang="scss">
/*.nav-container{
  position: relative;
  min-height: 100%;
  background-color: #f7f9fb;
}*/
.nav{
  position: fixed;
  top:0;
  left:0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height:56px;
  border-bottom: 1px solid $color-gray-0;
  background-color: #fff;
  z-index:100;
  @media (max-width: 991px) {
    .el-menu-item{
      padding: 0;
    }
    .el-drawer.ltr{
      display: none!important;
    }
    .logo{
      img:first-child{
        display: none;
      }
    }
  }
  @media (max-width: 767px) {
    #navTab,{
      display:none!important;
    }
    #smallNav{
      display:flex!important;
    }
  }
  #navTab{
    position: relative;
    flex:6;
    display:flex;
    align-items: center;
    height: 100%;
    .logo{
      height: 100%;
      display:flex;
      align-items: center;
      margin-left: 24px;
      img {
        width: 124.78px;
        height: 32px;
        margin-right: 16px;
      }
    }
  }
  .blank{
    flex:6;
    height: 50px;
  }
  #smallNav{
    display: none;
    align-items: center;
    font-weight: 600;
    .el-button{
      border: none;
      padding:0 10px;
      background-color: transparent;
    }
    .svg-icon{
      margin:0 8px;
      font-size: 25px;
      line-height: 25px;
      color:$color-blue-4!important;
    }
    .active-catalog{
      font-size: 16px;
      color:$color-blue-4;
    }
  }

}

::v-deep .el-drawer.ltr {
  width: 60%!important;
  height: 100%;
  .logo-1{
    display: flex;
    align-items: center;
    width: 124.78px;;
    img{
      width: 100%;
    }
  }

  .el-drawer__header{
    padding:15px 20px;
    border-bottom: 1px solid #d3dae3;
    margin-bottom: 0;
  }
  .el-drawer__body{
    .list-menu{
      li{
        margin-bottom: 10px;
        a{
          display: block;
          width: 100%;
          height: 40px;
          line-height: 20px;
          padding: 10px 20px;
          color:$color-blue-4;
          font-size: 16px;
          font-weight: 600;
        }
        a:hover{
          background-color: $color-blue-0;
        }
      }
    }
  }
  .el-drawer__close-btn{
    color:$color-blue-2;
  }
}


</style>



