import {getToken, setToken, removeToken, getApikey, setApikey, removeApikey, removeCurrentId} from '@/utils/auth'
import { getUserInfo,setUserInfo,removeUserInfo,getCurrentId,setCurrentId,removeAccount,removeSubList } from '@/utils/auth'
import {login,logout,signup,forgotPassword,resetPassword,getTimezoneList,updateProfile,getSystemConfig} from '@/api/user'
import { Message } from 'element-ui'
import {getProfileList, getUser} from "@/api/account";
import * as accountAPI from "@/api/account";


const state={
    token:getToken(),
    apiKey:getApikey(),
    email:'',
    user:getUserInfo() ? JSON.parse(getUserInfo()) : {},
    profile:localStorage.getItem('pubrio-profile')?JSON.parse(localStorage.getItem('pubrio-profile')):[],
    currentId:getCurrentId() ? JSON.parse(getCurrentId()) : {},
    timezoneList:localStorage.getItem('timezoneList')?JSON.parse(localStorage.getItem('timezoneList')):[],
    systemConfig:JSON.parse(localStorage.getItem('systemConfig') || '{}'),
    language: '',
    isSigned:true,
}
const mutations={
    setToken(state, {token,email}){
        state.token = token
        state.email = email
        if (state.isSigned) {
            setToken(token)
        }
    },
    setApikey(state,apiKey){
        state.apiKey = apiKey
        setApikey(apiKey)
    },
    setUserInfo(state,data){
        setUserInfo(JSON.stringify(data))
        state.user = data
    },
    setCurrentId(state,id){
        setCurrentId(JSON.stringify(id))
        state.currentId = id
    },
    removeUserInfo(state){
        removeToken()
        removeApikey()
        removeAccount()
        removeSubList()
        removeCurrentId()

    },
    setProfile(state, profile) {
         profile ? state.profile = profile : state.profile = []
        localStorage.setItem('pubrio-profile',JSON.stringify(state.profile))

    },
    setTimezoneList(state,data){
        data ? state.timezoneList = data : state.timezoneList = []
        localStorage.setItem('timezoneList',JSON.stringify(state.timezoneList))
    },
    setLanguage(state,data) {
        state.language = data;
    },
    setIsSigned(state,data) {
        state.isSigned = data;
    },
    setSystemConfig(state,data){
         data ? state.systemConfig = data : state.systemConfig = {}
        localStorage.setItem('systemConfig',JSON.stringify(state.systemConfig))
    }
}
const actions = {
  async login(context, data) {
    try {
      const result = await login(data);
      let token = result?.data.login.access_token;
      let apiKey = result?.data.login.user.api_key;
      let email = result?.data.login.user.email;
      context.commit('setToken', { token, email });
      context.commit('setApikey', apiKey);
      const user = await getUser();
      context.commit('setUserInfo', user?.data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getUserInfo(context) {
    try {
      const profile = await getProfileList();
      context.commit('setProfile', profile?.data);
      context.commit('setCurrentId', profile?.data[0]);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getTimezoneList(context) {
    try {
      const result = await getTimezoneList();
      context.commit('setTimezoneList', result?.data);
      return result?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async signup(context, data) {
    try {
      const result = await signup(data);
      let token = result?.data.signup.access_token;
      let apiKey = result?.data.signup.user.api_key;
      let email = result?.data.signup.user.email;
      context.commit('setToken', token);
      context.commit('setApikey', apiKey);
      return email;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async forgotPassword(context, data) {
    try {
      const result = await forgotPassword({ "email": data });
      Message.success(result?.data.forgot_password.message);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async resetPassword(context, data) {
    try {
      const result = await resetPassword(data);
      Message.success(result?.data.reset_password.message);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async logout(context) {
    try {
      const result = await logout({ "email": state.email });
      context.commit('removeUserInfo');
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async updateProfile(context, data) {
    try {
      await updateProfile(data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  /*----获取系统config----*/
  async getConfig(context, data) {
    try {
      const res = await getSystemConfig(data);
      context.commit('setSystemConfig', res?.data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
};


export default{
    namespaced: true,
    state,
    mutations,
    actions
}
