import request from '@/utils/request';

export function getWebsiteIntent(data){
    return request({
        method: 'post',
        url: '/intent/websites',
        data,
    })
}

export function deleteWebsiteIntent(data){
    return request({
        method: 'delete',
        url: '/intent/websites',
        data,
    })
}

export function getCompanyList(data){
    return request({
        method: 'post',
        url: '/intent/websites/leads',
        data,
    })
}

export function getCompanySession(data){
    return request({
        method: 'post',
        url: '/intent/websites/sessions',
        data,
    })
}

export function getTechnology(data){
    return request({
        method: 'post',
        url: '/technologies/lookup',
        data
    })
}

export function getGenericList(data){
    return request({
        method: 'post',
        url: '/contacts/generic',
        data
    })
}


export function getCompanyOverview(data){
    return request({
        method: 'post',
        url: '/companies/lookup',
        data
    })
}

export function addWebsite(data){
    return request({
        method: 'post',
        url: '/intent/websites/add',
        data
    })
}

export function getAd(params){
    return request({
        method: 'get',
        url: '/advertisements',
        params
    })
}

export function getVerticals(params){
    return request({
        method: 'get',
        url: '/verticals',
        params
    })
}

// employee list
export function getEmployeeList(data){
    return request({
        method: 'post',
        url: '/peoples/search',
        data
    })
}

// employee detail
export function getEmployeeDetail(data){
    return request({
        method: 'post',
        url: '/peoples/lookup',
        data,
    })
}

// export 
export function getLeadsExportData(data,cb){
    return request({
        method: 'post', 
        url: '/intent/websites/leads/export',
        data,
        responseType: 'blob',
        onDownloadProgress(e) {
            const complete = e.loaded / e.total * 100;
            cb && cb(Math.round(complete));
        }
    })
}

// redeem
export function redeemPeople(data){
    return request({
        method: 'post',
        url: '/redeem/peoples',
        data,
    })
}

// purchase credit
export function purchaseCredit(data){
    return request({
        method: 'post',
        url: '/billing/credits/purchase',
        data,
    })
}

// reset
export function resetEmplyeeInfo(data){
    return request({
        method: 'post',
        url: '/profile/reset',
        data,
    })
}

// getTechnology
export function getTechnologyList(data){
    return request({
        method: 'post',
        url: '/technologies/tags',
        data,
    })
}

export function activeWebsite(data){
    return request({
        method: 'post',
        url: '/intent/websites/activate',
        data,
    })
}




